<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    <TopMessage msg="南青山一丁目の癒し・美容業専用レンタルスペース"/>
  </div>
  
</template>

<script>
// @ is an alias to /src
import TopMessage from '@/components/TopMessage.vue'

export default {
  name: 'TopView',
  components: {
    TopMessage
  }
}
</script>
