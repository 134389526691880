<template>
  <body id="page-top">
    <Header />
    <router-view />
    <GoogleMap />
    <Footer />
</body>
</template>

<script>
  import Header from "./components/Header"
  import Footer from "./components/Footer"
  import GoogleMap from "./components/GoogleMap"

  export default {
      name: 'app',
      components: { Header, GoogleMap, Footer },

      methods : {
      setMeta(route){
        // タイトルを設定
        if(route.meta.title){
          let setTitle = route.meta.title;
          document.title = setTitle;
        }
        // ディスクリプションを設定
        if(route.meta.desc){
          let setDesc = route.meta.desc;
          document.querySelector("meta[name='description']").setAttribute('content', setDesc)
        }
      }
      },
      mounted(){
        let route = this.$route;
        this.setMeta(route);
      },
      watch: { 
        '$route' (route) {
          this.setMeta(route);
        }
      }
  }
  
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #FFF;
}
nav a:hover.router-link-exact-active {
  color: #6a4229;
}
</style>
