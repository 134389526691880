<template>
    <!-- Services-->
  <section class="page-section" id="services">
    <div class="">
      <div class="text-center">
        <div id="" class="contact">
            <h2>お問い合わせ</h2>
            <form class="contact-form" method="post" action="">
                <table>
                    <tbody>
                        <tr>
                            <th>お名前</th>
                            <td>
                                <input class="form-control" v-model="name" placeholder="例）田中 太郎">
                                <p class="error">{{ errors.name }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>フリガナ</th>
                            <td>
                                <input class="form-control" v-model="kana" placeholder="例）タナカ タロウ">
                                <p class="error">{{ errors.kana }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>電話番号</th>
                            <td>
                                <input class="form-control" v-model="tel" placeholder="例）09012341234">
                                <p class="error">{{ errors.tel }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>
                                <input class="form-control" v-model="email" placeholder="例）info@kaon-r.jp">
                                <p class="error">{{ errors.email }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>コメント</th>
                            <td>
                                <textarea name="" v-model="comment"></textarea>
                                <p class="error">{{ errors.comment }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button v-if="!valid" :disabled="!valid" type="button">未入力の必須項目があります</button>
                <button v-else-if="valid" type="button" @click="submit">送信</button>
            </form>
            <transition name="fade">
                <div v-if="result" class="contact-result">{{ result }}</div>
            </transition>
        </div>
    </div>
    </div>
    </section>
</template>


<script>
    import axios from 'axios'
    export default {
        data: function() {
            return {
                name: '',
                kana: '',
                tel: '',
                email: '',
                comment: '',
                result: '',
            }
        },
        computed: {
            chackName: function(){
                if(!this.name){
                    return 'お名前を入力してください';
                }
                return '';
            },
            chackKana: function(){
                if(!this.kana){
                    return 'フリガナを入力してください';
                } else if(!this.validKatakana(this.kana)){
                    return 'フリガナをカタカナで入力してください';
                }
                return '';
            },
            checkTel: function(){
                if(!this.tel){
                    return '電話番号（ハイフン無し）を入力してください';
                } else if(!this.validTel(this.tel)){
                    return 'ハイフン無し電話番号を正しく入力してください';
                }
                return '';
            },
            chackEmail: function(){
                if(!this.email){
                    return 'メールアドレスを入力してください';
                } else if(!this.validEmail(this.email)){
                    return 'メールアドレスを正しく入力してください';
                }
                return '';
            },
            errors: function() {
                const errors = {
                    'name': this.chackName,
                    'kana': this.chackKana,
                    'tel': this.checkTel,
                    'email': this.chackEmail,
                };
                for (var key in errors) {
                    if (errors[key] === '' || errors[key] === null || errors[key] === undefined) {
                        delete errors[key];
                    }
                }
                return errors;
            },
            valid: function() {
                return !Object.keys(this.errors).length;
            }
        },
        methods: {
            submit: async function(){
                const result = await this.send();
                const url = "/thanks";
                this.result = result; //ページ移動
                if(result === '送信完了'){
                    console.log('OK');
                    location.href = url; //ページ移動
                    this.clear();
                }
            },
            send: async function(){
                const url = 'https://api2.kaon-r.jp/contact/send.php';
                const params = {
                    "お名前": this.name,
                    "フリガナ": this.kana,
                    "連絡先": this.tel,
                    "email": this.email,
                    "コメント": this.comment,
                }
                return await axios
                    .post(url, params)
                    .then(function(res){
                        return res.data;
                    })
                    .catch(function(error){
                        console.log(error);
                });
            },
            validKatakana: function(kana) {
                const re = /^[ァ-ンｧ-ﾝ\x20\u3000ﾞﾟ]*$/;
                return re.test(kana);
            },
            validTel: function(tel) {
                const re = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/;
                return re.test(tel);
            },
            validEmail: function(email) {
                const re = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
                return re.test(email);
            },
            clear: function(){
                this.name = '';
                this.kana = '';
                this.tel= '';
                this.email = '';
                this.comment = '';
            }
        }
    }
    </script>




<style scoped>
    div, p, h2, ul, li {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    textarea {
        resize: vertical;
        width: 100%;
        min-height: 300px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }
    textarea:focus-visible {
        color: #6a4229;
        background-color: #fff;
        border-color: #8c705e;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(145 97 0 / 28%);
    }
    .contact {
        width: 100%;
        max-width: 960px;
        padding: 28px;
        margin: 130px auto 0 auto;
    }
    .contact h2 {
        margin: 0 0 28px 0;
    }

    .contact-form table tbody tr td input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .contact-form .error {
        margin: 0;
        color: #ff0000;
        font-weight: bold;
        font-size: .85rem;
    }
    .contact-form button {
        display: block;
        width: 300px;
        padding: 10px 0;
        margin: 0 auto;
        color: #ffffff;
        border: 0;
        box-shadow: none;
        background-color: #384878;
        cursor: pointer;
    }
    .contact-form button:disabled {
        background-color: #a5a5a5;
    }
    .contact-result {
        padding: 5px 0;
        margin: 28px auto 0 auto;
        color: #ffffff;
        box-sizing: border-box;
        background-color: #00c2bc;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .7s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 14px;
        margin: 40px 0;
    }
    table th {
        width: 22%;
        background: #8c705e;
        box-sizing: border-box;
        padding: 15px;
        vertical-align: middle;
        font-weight: bold;
        text-align: center;
        color: #fff;
        border: #fff 1px solid;
    }
    table td {
        background: #fff;
        box-sizing: border-box;
        padding: 15px;
        vertical-align: top;
        text-align: left;
        border: #fff 1px solid;
    }
    @media all and (max-width: 767px) {
        table th, table td {
            display: block;
            width: 100%;
            border-bottom: none;
        }
        table tr:last-child td:last-child {
            border-bottom: 1px solid #ccc;
        }
    }
    </style>