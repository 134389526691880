import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/TopView.vue'
import WinAoyama from '../views/WinAoyamaView.vue'
import ReserveView from '../views/ReserveView.vue'
import ContactView from '../views/ContactView.vue'
import TradelawView from '../views/TradelawView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'トップ | 南青山一丁目の癒し・美容業専用レンタルスペース' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { title: 'about | 南青山一丁目の癒し・美容業専用レンタルスペース' }
    
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: () => import(/* webpackChunkName: "about" */ '../views/ThanksView.vue'),
    meta: { title: '送信完了 | 南青山一丁目の癒し・美容業専用レンタルスペース' }
  },
  {
    path: '/win_aoyama',
    name: 'win_aoyama',
    component: WinAoyama,
    meta: { title: 'ウィン青山 | 南青山一丁目の癒し・美容業専用レンタルスペース' }
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: ReserveView,
    meta: { title: 'ご予約 | 南青山一丁目の癒し・美容業専用レンタルスペース' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: { title: 'お問い合わせ | 南青山一丁目の癒し・美容業専用レンタルスペース' }
  },
  {
    path: '/tradelaw',
    name: 'tradelaw',
    component: TradelawView,
    meta: { title: '特定商取引法に基づく表記 | 南青山一丁目の癒し・美容業専用レンタルスペース' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
