<template>
    <h1 class="fs-3 pt-5">{{ msg }}</h1>
</template>

<script>
  export default {
    //name: 'TopView',
    props: {
      msg: String
    }
  }
  </script>