<template>
<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <a class="navbar-brand" href="/"><img src="../assets/img/kaonR_logo.svg" alt="..." /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" v-on:click='isActive01=!isActive01'>
                    Menu
                    <i class="fas fa-bars ms-1"></i>
                </button>
                <div class="collapse navbar-collapse" v-bind:class='{show:isActive01}' id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        <li class="nav-item"><a v-on:click='isActive01=!isActive01' class="nav-link" href="https://kaon-r.jp/#services">Services</a></li>
                        <li class="nav-item"><a v-on:click='isActive01=!isActive01' class="nav-link" href="https://kaon-r.jp/#portfolio">Points</a></li>
                        <li class="nav-item"><a v-on:click='isActive01=!isActive01' class="nav-link" href="https://kaon-r.jp/#about">Flow</a></li>
                        <li class="nav-item"><a v-on:click='isActive01=!isActive01' class="nav-link" href="https://kaon-r.jp/#team">Gallery</a></li>
                        <li class="nav-item"><a v-on:click='isActive01=!isActive01' class="nav-link" href="https://kaon-r.jp/#qa">Q&A</a></li>
                        <li class="nav-item"><a v-on:click='isActive01=!isActive01' class="nav-link" href="https://kaon-r.jp/#contact">Contact</a></li>
                        <li class="nav-item"><a v-on:click='isActive01=!isActive01' class="nav-link" href="https://kaon-r.jp/#access">Access</a></li>
                    </ul>
                </div>
            </div>
        </nav>
</template>

<script>
    export default {
        name: 'site-header',
        data(){
      return {
        isActive01: false
      }
    }
    }
</script>


<style scoped>

</style>