<template>

    <section class="page-section bg-light pb-5" id="access">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase mb-0">Access</h2>
                <h3 class="section-subheading text-muted mt-0 mb-5">アクセス</h3>
                <a class="btn btn-primary btn-xl text-uppercase" data-bs-toggle="modal" href="#MapModal">詳細はこちら</a>
            </div>
        </div>
    </section>
    <GMapMap
      :center="center"
      :zoom="17"
      :options="{streetViewControl: false}"
      map-type-id="terrain"
      style="width: 100%; height: 600px"
      @dragend="onDragEnd"
    >
    <GMapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :draggable="true"
      @click="openMarker(m.id)"
    >
    <GMapInfoWindow
      :closeclick="true"
      @closeclick="closeMarker"
      :opened="openedMarkerID === m.id"
    >
    <div><img src="https://kaon-r.jp/img/kaonR_logo.15c392da.svg" alt="..." width="100px"/>{{ m.id }}</div>
    </GMapInfoWindow>
    </GMapMarker>
    </GMapMap>
  </template>
  <script>
  export default {
    name: 'App',
    data() {
      return {
        openedMarkerID: null,
        center: {lat: 35.671543, lng: 139.723904},
        markers: [
          {
            id: 1,
            position: {
              lat: 35.671543,
              lng: 139.723904
            },
          },
          {
          id: 2,
          position: {
            lat: 35.666171,
            lng: 139.713766
          },
        },
        ]
      }
    },
    methods: {
      openMarker(id) {
        this.openedMarkerID = id;
      },
      closeMarker() {
        console.log("closing marker");
        this.openedMarkerID = null;
      },
    },
  };
  </script>