<template>
    <!-- Footer-->
    <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">Copyright &copy; kaonR 2023</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="https://twitter.com/kaonR20221001" target="_blank" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/profile.php?id=100086545601576" aria-label="Facebook" target="_blank"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/kaon_r/" target="_blank" aria-label="instagram"><i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end fMenu">
                        <a class="text-decoration-none me-3" href="tradelaw">特定商取引法に基づく表記</a>
                        <!--
                        <a class="text-decoration-none me-3" href="win_aoyama">Privacy Policy</a>
                        <a class="text-decoration-none me-3" href="about">About</a>
                        <a class="text-decoration-none me-3" href="thanks">Thanks</a>
                        -->
                    </div>
                </div>
            </div>
        </footer>



        <!-- Portfolio Modals-->
        <!-- Portfolio item 1 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">タオル類</h2>
                                    <p class="item-intro text-muted">タオル類はレンタル無料です。</p>
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/towel.jpg" alt="..." />
                                    <p>下敷き、上掛けともに大判のタオルを無料で貸し出ししています。またフェイスタオルはお一人様5枚までご用意（ベッドには不織布シーツを敷いてご利用下さい）かさばるタオルを持参しなくても済みます。</p>
                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Portfolio item 2 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal2" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">音楽</h2>
                                    <p class="item-intro text-muted">リラックスできる音楽の中でご利用ください。</p>
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/music.jpg" alt="..." />
                                    <p>スピーカー搭載、Bluetooth対応のミニコンポを完備。ヒーリングCDも無料で貸し出ししております。</p>
                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Portfolio item 3 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal3" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">ベッド</h2>
                                    <p class="item-intro text-muted">最適な高さで施術をご利用ください。</p>
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/bed.jpg" alt="..." />
                                    <p>電動式昇降ベッドなので施術者の身長を選びません。</p>
                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Portfolio item 4 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal4" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">冷蔵庫・ウォーマー</h2>
                                    <p class="item-intro text-muted">季節に応じて最適なおしぼりなどに。</p>
                                    <div class="row">
                                        <div class="col-lg-6 col-sm-12">
                                            <img class="img-fluid d-block mx-auto" src="../assets/img/fridge.jpg" alt="..." />
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <img class="img-fluid d-block mx-auto" src="../assets/img/warmer.jpg" alt="..." />
                                        </div>
                                    </div>
                                    <p>ポータブル温冷冷蔵庫がございます。500mlのペットボトル4本入ります。ご自由にご利用下さい。</p>

                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Portfolio item 5 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal5" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">パーテーション</h2>
                                    <p class="item-intro text-muted">荻替えや仕切りにご利用ください。</p>
                                    <div class="row">
                                        <div class="col-lg-6 col-sm-12">
                                            <img class="img-fluid d-block mx-auto" src="../assets/img/partition.jpg" alt="..." />
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <img class="img-fluid d-block mx-auto" src="../assets/img/partition2.jpg" alt="..." />
                                        </div>
                                    </div>
                                    <p>170cmの高さがあるためお客様のお着替えの際に目隠しができ安心です。</p>
                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Portfolio item 6 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal6" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">お着替え</h2>
                                    <p class="item-intro text-muted">レンタル無料です。</p>
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/gown.jpg" alt="..." />
                                    <p>ラップ式ガウンをご用意しております（ボタン止めタイプ）。クローゼットに入っています。無料でご利用ください。</p>
                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Portfolio item 7 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal7" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">消耗品</h2>
                                    <p class="item-intro text-muted">必要な消耗品もご用意があります。</p>
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/S__50946183.jpg" alt="..." />
                                    <p>ベッド用不織布、フェイスペーパーは無料でご用意がございます（お着替え・紙ショーツはご自身でご用意下さい）。使用後は規定通りの処分をお願いいたします。</p>
                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Portfolio item 8 modal popup-->
        <div class="portfolio-modal modal fade" id="portfolioModal8" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <h2 class="text-uppercase">食器</h2>
                                    <p class="item-intro text-muted">施術後のコミュニケーションタイムに。</p>
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/cup.jpg" alt="..." />
                                    <p>和風・洋風の食器をご用意しております。お飲みいただくお茶によってご利用ください。</p>
                                    <!--
                                    <ul class="list-inline">
                                        
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    -->
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- MapModal modal popup-->
        <div class="portfolio-modal modal fade" id="MapModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <h2 class="text-uppercase">アクセス</h2>
                            <p class="item-intro text-muted">当シェアサロンはレンタル無料です。</p>
                            <div class="col-lg-4 col-sm-6">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/map/0.jpg" alt="..." />
                                    <p>5番出口を出て、右後ろ向きに50mほど歩くとコンビニ（ポプラ）が見えます。</p>

                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/map/1.jpg" alt="..." />
                                    <p>ポプラ手前の道を右に入って50mほど進むと、ビル左手にレストラン街入口がございます。（※レストラン街は入口ではございませんのでお気を付けください）</p>

                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/map/2.jpg" alt="..." />
                                    <p>そこから少し進んだ先に、エスカレーターがあるので2階まで上がってください。</p>

                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/map/3.jpg" alt="..." />
                                    <p>2階エントランスに着きましたら、奥にエレベーターがございます。</p>

                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="modal-body">
                                    <!-- ポイントモーダル-->
                                    <img class="img-fluid d-block mx-auto" src="../assets/img/map/4.jpg" alt="..." />
                                    <p>左奥にあるエレベーターでサロンへ向かって下さい。（部屋番号は見学/レンタル日程が決まった際にお伝えします）</p>

                                </div>
                            </div>

                            
                            <div class="row justify-content-center">
                            <div class="col-lg-8">
                            <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                <i class="fas fa-xmark me-1"></i>
                                Close
                            </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



</template>

<script>
    export default {
        name: 'site-footer'
    }
</script>



<style scoped>
.fMenu a {
    color: #6a4229;
}

.fMenu a:hover {
    color: #6a4229;
}
</style>