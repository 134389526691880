<template>
  <header class="masthead">
    <div class="container">
      <div class="masthead-subheading">
        {{ msg }}<br />料金は1時間1,440円/〜
      </div>
      <div class="masthead-heading">
        <img class="main_img" src="../assets/img/kaonR_logo.svg" alt="..." />
      </div>
      <a class="btn btn-primary btn-xl text-uppercase" href="/reserve"
        >ご予約・見学受付はこちら</a
      >
    </div>
  </header>
  <!-- Services-->
  <section class="page-section" id="services">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase mb-0">Services</h2>
        <h3 class="section-subheading text-muted mt-0">サービス</h3>
      </div>
      <div class="row text-center">
        <div class="col-md-4">
          <span class="fa-stack fa-4x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-yen-sign fa-stack-1x fa-inverse"></i>
          </span>
          <h4 class="my-3">初期費用0・固定費0</h4>
          <p class="text-muted">
            無駄な固定費や初期費用は一切かかりません。必要なときに必要な時間だけご利用ください。
          </p>
        </div>
        <div class="col-md-4">
          <span class="fa-stack fa-4x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-cart-flatbed-suitcase fa-stack-1x fa-inverse"></i>
          </span>
          <h4 class="my-3">設備充実</h4>
          <p class="text-muted">
            サロンに必要なタオルなど無料でご利用いただけます。大きな荷物を準備いただく必要はありません。
          </p>
        </div>
        <div class="col-md-4">
          <span class="fa-stack fa-4x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-person-shelter fa-stack-1x fa-inverse"></i>
          </span>
          <h4 class="my-3">本格的内装</h4>
          <p class="text-muted">
            サロン経営のオーナーが設計・準備しております。利用いただくお客様のおもてなしに満足いただけます。
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- Portfolio Grid-->
  <section class="page-section bg-light" id="portfolio">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase mb-0">Points</h2>
        <h3 class="section-subheading text-muted mt-0">ポイント</h3>
      </div>
      <div class="row">
        <div class="col-lg-4 col-sm-6 mb-4">
          <!-- Portfolio item 1-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal1"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img class="img-fluid" src="../assets/img/towel.jpg" alt="..." />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">タオル類</div>
              <div class="portfolio-caption-subheading text-muted">
                下敷き、上掛けともに大判のタオルを無料で貸し出ししています。…
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <!-- Portfolio item 2-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal2"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img class="img-fluid" src="../assets/img/music.jpg" alt="..." />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">音楽</div>
              <div class="portfolio-caption-subheading text-muted">
                スピーカー搭載、Bluetooth対応のミニコンポを完備。ヒーリングCDも無料で…
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <!-- Portfolio item 3-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal3"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img class="img-fluid" src="../assets/img/bed.jpg" alt="..." />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">ベッド</div>
              <div class="portfolio-caption-subheading text-muted">
                電動式昇降ベッドなので施術者の身長を選びません。
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <!-- Portfolio item 4-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal4"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img class="img-fluid" src="../assets/img/fridge.jpg" alt="..." />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">冷蔵庫・ウォーマー</div>
              <div class="portfolio-caption-subheading text-muted">
                ポータブル温冷冷蔵庫がございます。500mlのペットボトル4本入ります。ご自由にご利用下さい。
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <!-- Portfolio item 5-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal5"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                class="img-fluid"
                src="../assets/img/partition.jpg"
                alt="..."
              />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">パーテーション</div>
              <div class="portfolio-caption-subheading text-muted">
                170cmの高さがあるためお客様のお着替えの際に目隠しができ安心です。
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <!-- Portfolio item 6-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal6"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img class="img-fluid" src="../assets/img/gown.jpg" alt="..." />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">お着替え</div>
              <div class="portfolio-caption-subheading text-muted">
                ラップ式ガウンをご用意しております（ボタン止めタイプ）…
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <!-- Portfolio item 6-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal7"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                class="img-fluid"
                src="../assets/img/S__50946183.jpg"
                alt="..."
              />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">消耗品</div>
              <div class="portfolio-caption-subheading text-muted">
                ベッド用不織布、フェイスペーパーは無料でご用意がございます（お着替え・…
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <!-- Portfolio item 6-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              href="#portfolioModal8"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <i class="fas fa-plus fa-3x"></i>
                </div>
              </div>
              <img class="img-fluid" src="../assets/img/cup.jpg" alt="..." />
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">食器</div>
              <div class="portfolio-caption-subheading text-muted">
                和風・洋風の食器をご用意しております。お飲みいただくお茶によってご利用ください。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About-->
  <section class="page-section" id="about">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase mb-0">Flow</h2>
        <h3 class="section-subheading text-muted mt-0">ご利用までの流れ</h3>
      </div>
      <ul class="timeline">
        <li>
          <div class="timeline-image">
            <img
              class="rounded-circle img-fluid"
              src="../assets/img/step1.jpg"
              alt="..."
            />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4>#1</h4>
              <h4 class="subheading">サロン見学</h4>
            </div>
            <div class="timeline-body">
              <p class="text-muted">
                初めての方には、当サロンルームがどのような雰囲気か体験していただけるよう、必ず事前に見学をお願いしております。まずはお気軽にご見学ください。下記フォームより承っております。※事前に必ず見学をお願いします【平日10時から11時頃でお願いしています】
              </p>
            </div>
          </div>
        </li>
        <li class="timeline-inverted">
          <div class="timeline-image">
            <img
              class="rounded-circle img-fluid"
              src="../assets/img/step2.jpg"
              alt="..."
            />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4>#2</h4>
              <h4 class="subheading">見学時または予約専用ページからご予約</h4>
            </div>
            <div class="timeline-body">
              <p class="text-muted">
                見学時または予約専用ページからご予約ください。必要項目を入力いただき送信してください。追って完了メールが送信されます。
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="timeline-image">
            <img
              class="rounded-circle img-fluid"
              src="../assets/img/step3.jpg"
              alt="..."
            />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4>#3</h4>
              <h4 class="subheading">予約完了後のメール確認</h4>
            </div>
            <div class="timeline-body">
              <p class="text-muted">
                メールの内容を確認の上、実際のご予約日時を確定いたします。流れはメールに記載がございますので、ご完了まで進めてください。ご利用料金のお支払いをお願い致します。※クレジットカードまたは銀行振込み決済となります。
              </p>
            </div>
          </div>
        </li>
        <li class="timeline-inverted">
          <div class="timeline-image">
            <img
              class="rounded-circle img-fluid"
              src="../assets/img/step4.jpg"
              alt="..."
            />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4>#4</h4>
              <h4 class="subheading">当日ご利用開始</h4>
            </div>
            <div class="timeline-body">
              <p class="text-muted">
                入り口の鍵にて入室ください。使用後は、次にご利用される方のために、規定に沿って清掃・片付けにご協力ください。またのご利用をお待ちしております。
              </p>
            </div>
          </div>
        </li>
        <li class="timeline-inverted">
          <div class="timeline-image">
            <h4 class="">
              終了
              <br />
              またのご利用
              <br />
              お待ちして
              <br />おります！
            </h4>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <!-- Team-->
  <section class="page-section bg-light" id="team">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase mb-0">Gallery</h2>
        <h3 class="section-subheading text-muted mt-0">ギャラリー</h3>
      </div>

      <div class="row">
        <swiper
          :modules="modules"
          :slides-per-view="1"
          navigation
          :pagination="{ clickable: true }"
          :scrollbar="{ draggable: true }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          loop="true"
          autoplay="{
            delay: 2500,
            disableOnInteraction: false
        }"
          :breakpoints="{
            '640': {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            '768': {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            '1024': {
              slidesPerView: 4,
              spaceBetween: 5,
            },
          }"
        >
          <!--  
      <swiper-slide>
            <div class="team-member pb-3">
              <img class="mx-auto rounded-circle" src="../assets/img/gallery/DSC07218.jpg" alt="..." />
              <h4>ベッド</h4>
              <p class="text-muted">入り口から雰囲気のあるサロンです。</p>
              <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand Twitter Profile"><i
                class="fab fa-twitter"></i></a>
              <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand Facebook Profile"><i
                class="fab fa-facebook-f"></i></a>
              <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/kaon_r/" aria-label="Parveen Anand LinkedIn Profile"><i
                class="fab fa-instagram"></i></a>
              
            </div>
        </swiper-slide>
        -->
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/1.jpg"
                alt="..."
              />
              <h4>ベッド</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/2.jpg"
                alt="..."
              />
              <h4>ベッド</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/3.jpg"
                alt="..."
              />
              <h4>ベッド</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/4.jpg"
                alt="..."
              />
              <h4>ソファ</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/5.jpg"
                alt="..."
              />
              <h4>ソファ</h4>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/6.jpg"
                alt="..."
              />
              <h4>パーテーション</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/7.jpg"
                alt="..."
              />
              <h4>クローゼット</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/8.jpg"
                alt="..."
              />
              <h4>トイレ</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/9.jpg"
                alt="..."
              />
              <h4>トイレ</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/10.jpg"
                alt="..."
              />
              <h4>オーディオ</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/11.jpg"
                alt="..."
              />
              <h4>オーディオ</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/12.jpg"
                alt="..."
              />
              <h4>キャビネット</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/13.jpg"
                alt="..."
              />
              <h4>キャビネット</h4>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/14.jpg"
                alt="..."
              />
              <h4>キャビネット</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/15.jpg"
                alt="..."
              />
              <h4>スリッパ</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/16.jpg"
                alt="..."
              />
              <h4>食器</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/17.jpg"
                alt="..."
              />
              <h4>食器</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/18.jpg"
                alt="..."
              />
              <h4>冷温蔵庫</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/19.jpg"
                alt="..."
              />
              <h4>冷温蔵庫</h4>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/20.jpg"
                alt="..."
              />
              <h4>鏡</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/21.jpg"
                alt="..."
              />
              <h4>スツール</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/22.jpg"
                alt="..."
              />
              <h4>ヒーター</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/23.jpg"
                alt="..."
              />
              <h4>加湿器</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team-member">
              <img
                class="mx-auto rounded-circle"
                src="../assets/img/gallery/24.jpg"
                alt="..."
              />
              <h4>昇降式スツール</h4>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="row">
        <div class="col-lg-8 mx-auto text-center mt-5">
          <p class="large text-muted">
            サロンに必要な設備や備品を取りそろえております。自身の経営するサロンに必要なものを同じ目線で準備しております。お客様のひとときのくつろぎを最大限に楽しんでいただけますように
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- Clients-->
  <!--
  <div class="py-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-3 col-sm-6 my-3">
          <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/microsoft.svg"
              alt="..." aria-label="Microsoft Logo" /></a>
        </div>
        <div class="col-md-3 col-sm-6 my-3">
          <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/google.svg" alt="..."
              aria-label="Google Logo" /></a>
        </div>
        <div class="col-md-3 col-sm-6 my-3">
          <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/facebook.svg" alt="..."
              aria-label="Facebook Logo" /></a>
        </div>
        <div class="col-md-3 col-sm-6 my-3">
          <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/ibm.svg" alt="..."
              aria-label="IBM Logo" /></a>
        </div>
      </div>
    </div>
  </div>
  -->
  <!-- qa-->
  <section class="page-section" id="qa">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase mb-0">QA</h2>
        <h3 class="section-subheading text-muted mt-0">よくある質問</h3>
      </div>
      <div class="accordion w-100" id="basicAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              入会金はありますか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              ございません。まずはお気軽に見学にいらして下さい。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              お支払い方法は何ができますか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseTwo"
            class="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              指定銀行振込とクレジット決済に対応してます（対応可能カード：VISA,MASTER,JCB,SAISON,AMEX,Diners,DISCOVER）
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              キャンセルの場合は キャンセル料金はかかりますか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              はい、かかります。キャンセルするタイミングによってキャンセル料金は変わります。下記をご確認ください。
              <br />
              • 8日より前:0%<br />
              • 7日前〜4日前:25%<br />
              • 3日前〜前日:50%<br />
              • 当日:100%<br />
              ※◯日前というのは23:59分を基準としております。例）1月1日予約の場合、12月31日23:59分までが前日キャンセル、それ以降は当日キャンセルとなります。それ意外のキャンセルに関しても同様となります。
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              予約の時間変更は対応してくれますか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              はい、当日内の時間変更のみ可能であれば対応させて頂きます。その場合はまずはご相談下さい。日をまたぐ日時の変更はキャンセルポリシーの対象となります。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              領収書はいただけますか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              クレジット決済の場合、正式な領収書として認められています。
              銀行振り込みの場合、振込明細書をご利用ください。
              当社で発行した領収書が必要な場合はお支払い時にお申し出ください。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              どのような業種・用途でも申込みは可能ですか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              主にセラピスト、エステティシャン、アイリスト、カウンセラー様にご利用いただいております。スクールや講座のご利用も可能です。ご相談下さい。
              宗教活動、政治活動、ネットワークビジネスでの利用など利用形態によってはお断りさせていただく場合がございます。
              ※ご利用者様、クライアント様共に女性専用サロンとなります。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              施術ベッドのサイズと機能を教えてください。
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              サイズは 縦幅185cm 横幅70cm です。電動昇降式/ 有孔タイプ（穴あき）
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              タオルのサイズを教えてください。
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              上掛けタオル（レンタル無料）<br />
              →90cm*160cmです<br /><br />
              下敷きタオル（レンタル無料）<br />
              →100cm*170cmです<br /><br />
              フェイスタオル（5枚までレンタル無料）<br />
              →80cm*35cmです
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              鍵の受け渡しはどのようになりますか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              ご予約日時が決まりましたら、入退室方法をメールか公式LINEにてお伝え致します。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              当日の時間延長は出来ますか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              ご利用日当日での延長の場合は、後の予約が空いている場合のみ可能です。管理者へ必ずご連絡をお願い致します。急な場合連絡がつかない事が御座います。その際は延長は不可とご判断下さい。
              追加料金のお支払は封筒をご用意しておりますので、お名前・お日にちをご記載頂きボックスの中に入れておいてください。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              入退室時間について
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              入室は、前にご予約が入っていない場合は10分前から可能です。原則ご予約時間通りに入退室をお願いいたします（掃除も含めた時間をご予約下さい）前日に管理者よりご予約状況をお伝えします。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              退出時間を過ぎてしまった場合どうすれば良いですか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              ご利用時間を超えてご利用になる場合は15分毎の延長料金を頂いております。
              15分/415円（税込）
              こちらを封筒をお使い頂き専用ボックスへ入れてください。
              ※入退室時間はセキュリティカメラにて記録されるようになっております。他のご利用者様の迷惑になるような場合はご利用を控えて頂く事がございます。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              掃除は何をすれば良いですか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              レンタルサロン使用後は玄関の棚の上にございます「清掃チェックリスト」に基づいて清掃を行っていただいております。
              掃除漏れや汚れている場合は写真を撮っていただき、清掃チェックリストと一緒にメールまたは公式LINEにてご連絡下さい。
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              レイアウトを替えても良いですか？
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style=""
          >
            <div class="accordion-body">
              部屋のレイアウトは自由に変更頂いて構いません。ただし、使用後は現状回復をお願い致します。
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact-->
  <section class="page-section" id="contact">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase mb-0">お問い合わせ</h2>
        <h3 class="section-subheading mt-0 text-white">
          お気軽にお問い合わせください。
        </h3>
      </div>
      <div class="text-center">
        <a class="btn btn-primary btn-xl text-uppercase m-1" href="/contact"
          >お問い合わせはこちら</a
        >
        <a class="btn btn-primary btn-xl text-uppercase m-1" href="/reserve"
          >ご予約・見学受付はこちら</a
        >
      </div>
    </div>
  </section>
</template>

<script>
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

export default {
  //name: 'TopView',
  props: {
    msg: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header.masthead .masthead-subheading {
  line-height: 140% !important;
}
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #fff;
}
a:hover {
  color: #6a4229;
}
.swiper-wrapper {
  height: 460px !important;
}

#basicAccordion {
  text-align: left;
}
.accordion-button {
  background-color: antiquewhite;
}
.accordion-body {
  word-wrap: break-word;
}

@media all and (max-width: 767px) {
  .timeline > li .timeline-image h4 {
    font-size: 10px;
    line-height: 12px;
    margin-top: 7px;
  }
}
@media (min-width: 1200px) {
  .timeline > li .timeline-image h4 {
    margin-top: 20px;
  }
}
</style>
