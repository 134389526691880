import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueGoogleMaps from '@fawmi/vue-google-maps'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/scss/custom.scss'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import VueAxios from 'vue-axios'


library.add(faHatWizard)


createApp(App)
.use(router)
.use(router)
.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBxLXIlnhEEwhwJP9f6jwH-rssaCBFFrcg',
    },
})
.use(VueAwesomeSwiper)
.component('font-awesome-icon', FontAwesomeIcon)
.use(VueAxios, axios)
.mount('#app');

