<template>
    <div class="win_aoyama">
      <TopMessage msg="【9月より月・木貸し切りレンタル中！タオル備品無料レンタル！】表参道駅から2分★アロマ、エステ、リラクの施術に最適です♪"/>
    </div>
    <section class="winAoyama pt-5" id="winAoyama">
        <div class="">
            <div class="row">
                <swiper
                :modules="modules"
                :slides-per-view="3"
                navigation
                :pagination="{ clickable: true }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                loop="true"
                :breakpoints="{
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: 40
                },
                '1024': {
                    slidesPerView: 5,
                    spaceBetween: 5
                }
                }"
                >
                    <swiper-slide>
                        <div class="team-member">
                        <img class="mx-auto" src="https://cdn.instabase.jp/image/upload/t_large/v1/uploads/room_image/image/116109/64908aa4-4ade-44ed-a741-88236a88ce43.jpg" alt="..." />
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="team-member">
                            <img class="mx-auto" src="https://cdn.instabase.jp/image/upload/t_large/v1/uploads/room_image/image/116109/64908aa4-4ade-44ed-a741-88236a88ce43.jpg" alt="..." />
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="team-member">
                            <img class="mx-auto" src="https://cdn.instabase.jp/image/upload/t_large/v1/uploads/room_image/image/116109/64908aa4-4ade-44ed-a741-88236a88ce43.jpg" alt="..." />
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="team-member">
                            <img class="mx-auto" src="https://cdn.instabase.jp/image/upload/t_large/v1/uploads/room_image/image/116109/64908aa4-4ade-44ed-a741-88236a88ce43.jpg" alt="..." />
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="team-member">
                            <img class="mx-auto" src="https://cdn.instabase.jp/image/upload/t_large/v1/uploads/room_image/image/116109/64908aa4-4ade-44ed-a741-88236a88ce43.jpg" alt="..." />
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="team-member">
                        <img class="mx-auto" src="https://cdn.instabase.jp/image/upload/t_large/v1/uploads/room_image/image/116109/64908aa4-4ade-44ed-a741-88236a88ce43.jpg" alt="..." />
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="container">
                <div class="row">
                    <a class="btn btn-dark rounded-pill" href="#overview" role="button">概要</a>
                    <a class="btn btn-dark rounded-pill" href="#pict" role="button">写真</a>
                    <a class="btn btn-dark rounded-pill" href="#reserve" role="button">予約</a>
                    <a class="btn btn-dark rounded-pill" href="#access" role="button">アクセス</a>
                    <a class="btn btn-dark rounded-pill" href="#review" role="button">レビュー</a>
                    <a class="btn btn-dark rounded-pill" href="#help" role="button">ヘルプ</a>
                </div>
            </div>
      </div>
    </section>

    <!--メインコンテンツ-->
    <section class="MainContents pt-5" id="MainContents">

    </section>


    <section class="MainContents pt-5" id="overview">
        <div class="text">
            <p>【9月以降月・木完全貸し切り可能です☆タオル類無料レンタルスタート！】
※注意事項を必ずお読みください↓
南青山表参道駅から徒歩2分。青山通りに面するビルの一室で好立地です！！室内はホテルの一室のような間接照明が大変落ち着く大人の隠れ家の雰囲気です。
★開業を考えているセラピストさん、フリーランスのセラピストさんにオススメです★（定額契約可能です※タオル・備品レンタル代込み）
アロマ、エステ、リラクゼーション利用の他セラピー、カウンセリング、技術研修や練習にもご利用いただけます。<br /><br />

■□■以下を必ずお読みいただいた上、事前に使用用途詳細についてメッセージをお願いします■□■<br /><br />

※月・木曜日以外は通常営業を行っているサロンのため、曜日や時間帯は要相談でお願いします（事前にメッセージをお願いします）<br /><br />
※女性限定（施術者・講師・クライアント共に）
※事前に見学をして戴いた方を優先してご案内しております【土・日・祝除く】
※ ベッドを使用される場合には、大きいシーツ、タオル、不織布（160cm×90cm以上）をお持ち込み頂きベッドの上に敷いてご利用ください（不織布無料）
※キャリーバッグでお越しの際は店内は引きずらないで下さい。
※スリッパのご持参をお願いします。
</p>
        </div>
    </section>
    <section class="MainContents pt-5" id="pict">
        写真写真写真写真写真写真写真
    </section>
    <section class="MainContents pt-5" id="reserve">
        予約予約予約予約予約予約
    </section>
    <section class="MainContents pt-5" id="access">
        アクセスアクセスアクセスアクセスアクセス
    </section>
    <section class="MainContents pt-5" id="review">
        レビューレビューレビューレビューレビューレビュー
    </section>
    <section class="MainContents pt-5" id="help">
        ヘルプヘルプヘルプヘルプヘルプ
    </section>
      
  </template>
  
  <script>
  // @ is an alias to /src
  import TopMessage from '@/components/WinAoyama.vue'



  // import Swiper core and required modules
  import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';


  
  export default {
    //name: 'TopView1',
    components: {
      TopMessage,

      Swiper,
      SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },
  }
  </script>

<style scoped>
    h1 {

    }
    .win_aoyama {
        padding: 94px 0 0 0;
    }
    .MainContents {
        height: 500px;
        background-color: gray;
        color: white;
        margin: 0 0 20px 0;
    }
    .text {
        text-align: left;
    }
    .team-member {
        margin-bottom: 3rem;
        text-align: center;
    }
    .team-member img {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 10px;
    }
    .team-member h4, .team-member .h4 {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }

    .img-brand {
        height: 0;
    }
</style>