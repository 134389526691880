<template>
    <!-- Services-->
<section class="page-section" id="services">
    <div class="text-center">
        <div id="" class="contact">
            <h2>特定商取引法に基づく表記</h2>
                <table>
                    <tbody>
                        <tr>
                            <th>事業者名</th>
                            <td>
                                レンタルスペースkaonR
                            </td>
                        </tr>
                        <tr>
                            <th>代表者</th>
                            <td>
                                水谷理子
                            </td>
                        </tr>
                        <tr>
                            <th>事業者所在地</th>
                            <td>
                                東京都港区南青山2-2-15
                            </td>
                        </tr>
                        <tr>
                            <th>問い合わせ</th>
                            <td>
                                03-6804-1059<br />
                                サービスについてのお問い合わせは、メールにて受け付けております。<br />
                                ご不便をお掛けいたしますが、<a href="contact">こちら</a>からお問い合わせください。
                            </td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>
                                ■ご予約・見学<br />
                                reserve&#64;kaon-r.jp<br /><br />
                                ■お問い合わせ（上記以外）<br />
                                contact&#64;kaon-r.jp
                            </td>
                        </tr>
                        <tr>
                            <th>サービス利用価格</th>
                            <td>
                                ご予約・見学受付のページ内に表示する価格になります。
                            </td>
                        </tr>
                        <tr>
                            <th>代金の支払い方法・時期</th>
                            <td>
                                ■クレジットカード<br />
                                ご予約時に代金を決済していただきます。<br /><br />
                                ■コンビニ決済／ペイジー（銀行ATM・インターネットバンキング決済）<br />
                                レンタルスペースのご予約日の翌日から起算して3日以内（以下「コンビニ決済等のお支払い期限」といいます）にお支払いいただきます。<br />
                                コンビニ決済等のお支払い期限までにお支払いを確認できていない場合には、原則としてご予約を自動でキャンセルさせていただきます。<br /><br />
                                お支払いの際に生じる手数料は、スペース利用者様にご負担いただきます。
                            </td>
                        </tr>
                        <tr>
                            <th>サービス利用料金以外の費用</th>
                            <td>
                                お支払い手数料。<br />レンタルスペース利用料金のお支払い手数料は、スペース利用者様負担となっております。
                            </td>
                        </tr>
                        <tr>
                            <th>提供時期</th>
                            <td>
                                レンタルスペースご利用日当日が役務の提供時期となります。
                            </td>
                        </tr>
                        <tr>
                            <th>キャンセル</th>
                            <td>
                                キャンセルポリシーにて既定のキャンセル料金をいただきます。<br />
                                レンタルスペースのご予約前に、事前にご確認ください。
                            </td>
                        </tr>
                        <tr>
                            <th>推奨環境・対応端末</th>
                            <td>
                                PC端末または、モバイル／タブレット端末にてご利用可能です。
                            </td>
                        </tr>
                        <tr>
                            <th>不良品の取り扱い条件</th>
                            <td>
                                商品の性質上、返品は承っておりません。
                            </td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
</section>
</template>







<style scoped>
    div, p, h2, ul, li {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    textarea {
        resize: vertical;
        width: 100%;
        min-height: 300px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }
    textarea:focus-visible {
        color: #6a4229;
        background-color: #fff;
        border-color: #8c705e;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(145 97 0 / 28%);
    }
    .contact {
        width: 100%;
        max-width: 960px;
        padding: 28px;
        margin: 130px auto 0 auto;
    }
    .contact h2 {
        margin: 0 0 28px 0;
    }

    .contact-form table tbody tr td input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .contact-form .error {
        margin: 0;
        color: #ff0000;
        font-weight: bold;
        font-size: .85rem;
    }
    .contact-form button {
        display: block;
        width: 300px;
        padding: 10px 0;
        margin: 0 auto;
        color: #ffffff;
        border: 0;
        box-shadow: none;
        background-color: #384878;
        cursor: pointer;
    }
    .contact-form button:disabled {
        background-color: #a5a5a5;
    }
    .contact-result {
        padding: 5px 0;
        margin: 28px auto 0 auto;
        color: #ffffff;
        box-sizing: border-box;
        background-color: #00c2bc;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .7s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 14px;
        margin: 40px 0;
    }
    table th {
        width: 22%;
        background: #8c705e;
        box-sizing: border-box;
        padding: 15px;
        vertical-align: middle;
        font-weight: bold;
        text-align: center;
        color: #fff;
        border: #fff 1px solid;
    }
    table td {
        background: #fff;
        box-sizing: border-box;
        padding: 15px;
        vertical-align: top;
        text-align: left;
        border: #fff 1px solid;
    }
    @media all and (max-width: 767px) {
        table th, table td {
            display: block;
            width: 100%;
            border-bottom: none;
        }
        table tr:last-child td:last-child {
            border-bottom: 1px solid #ccc;
        }
    }
    </style>